$primary-color: #0022EB;
$white: #ffffff;
$black: #000000;
$red: #f44336;
$red-light: #ff5d5d;
$dark-gray: #1b2124;
$grey-shade: #414141;
$grey-shade1: #1c2124;

.bg-primary{
    background-color: $primary-color !important;
}
// backgroun-color
$bg-color:#fff;
$bg-color1:#F8F9FC;
$bd-radius:50%;
$f-16:16px;
$f-14:14px;

// mixins

@mixin display-content($display,$justity, $alignment){
    display:$display ;
    justify-content:$justity ;
    align-items: $alignment;
}