/* You can add global styles to this file, and also import other style files */
// Variable scss //
@import "variables";
@import "globalfonts";
@import '~@ctrl/ngx-emoji-mart/picker';
// End //

html,
body {
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}
a{
  cursor: pointer !important;
}
.border-1-blue{
  border: 1px solid $primary-color !important;
}
.mat-progress-bar-fill::after {
  background-color: #1A09F4;
}
input.form-control:focus {
  outline: 0;
  color: #495057;
  background-color: #ffffff;
  border-color: #0002ff;
  outline: 0;
  box-shadow: none;
}
.swal2-title {
    font-family: 'gibson_book';
}
.swal2-styled.swal2-confirm {
  background-color: #1a09f4 !important;
  font-family: 'gibson_book';
}
body {
  margin: 0;
  font-family: "gibson1-300" !important;
}

.modal-content {
  background: none !important;
  overflow: hidden;
}

.modal-header {
  background-color: #0022eb;
  color: #fff;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  display: flex;
  align-items: center;
  height: 51px;

  h4 {
    margin: 0px;
    text-align: center;
    width: 100%;
    font-family: "grotesk_medium";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }

  .close {
    color: #fff;
  }
}

a {
  font-family: "gibson_book";
  font-weight: 500;
  font-size: 15px;
}

.modal-body {
  background: #fff;
}

.modal-header {
  border-radius: 0px !important;
}

.modal-header .close {
  margin: 0px !important;
  position: absolute;
  right: 18px;
  padding: 0px !important;
}

.modal-footer {
  background: #fff;
}

.modal-dialog .modal-content {
  border-radius: 2px;
}

.audio-video-content .modal-content {
  border-radius: 2px !important;
  -webkit-border-radius: 0px;
}

.audio-video-content {
  display: flex;
  align-items: center;
  min-height: 100%;
  padding: 1rem 0;

  .modal-content {
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
  }
}

.modal-dialog.audio-video-content.confirmation-popup.thank-you p.small-content {
  display: inline;
}

// .public-chat {
//   &:before {
//     background: linear-gradient(0deg, #ffffff54 45.5%, rgba(128, 128, 128, 0.32) 379.4%);
//     content: "" !important;
//     position: absolute !important;
//     width: 100%;
//     height: 18px;
//     bottom: -21px;
//     top: 0;
//     right: -4px;
//     z-index: 999;
//   }
// }

// Modal Design Width Set //
.modal-dialog {
  &.cating-answer-layout {
    max-width: 618px;
    margin: 0em auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &.modal-layout-420 {
    max-width: 420px !important;
    margin: 0em auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &.modal-layout-618 {
    max-width: 618px !important;
    margin: 0em auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &.video-audio-mute {
    width: 225px;
    height: 100%;
    position: absolute;
    right: 0;
    left: 0;
    top: 50%;
  }

  &.ask-question {
    width: 485px;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0px auto;
  }

  &.event-information-content {
    max-width: 737px;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0px auto;
  }

  &.audiance-screen-share {
    max-width: 828px !important;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0px auto;
  }

  &.share-screen-layout {
    max-width: 828px;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0px auto;
  }

  &.choosing-db {
    max-width: 849px;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0px auto;
  }

  &.admit-speaker {
    max-width: 536px;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0px auto;
  }
}

// .common-tab-shadow {
// 	&:before {
//     background: linear-gradient(0deg, #ffffff54 45.5%, rgb(128 128 128 / 32%) 379.4%);
//     content: "";
//     position: absolute;
//     width: 100%;
//     height: 18px;
//     bottom: -21px;
// 	}
// 	position: relative;
// }

// end //

@media (max-width: 576px) {
  .modal-dialog {
    &.audio-video-content {
      max-width: 80%;
      margin: 0 auto;
    }

    &.join-event-permission,
    &.confirmation-popup {
      max-width: 50%;
    }

    &.event-information-content {
      max-width: 70%;
      margin: 0 auto;
    }

    &.gift-design {
      max-width: 90%;
    }

    &.ask-question {
      max-width: 50%;
    }

    &.connect-user,
    &.connect-req {
      max-width: 40%;
    }

    &.connect-user-information {
      max-width: 90%;
    }

    &.chating-answer {
      max-width: 90%;
    }
  }

  .modal-dialog.audiance-screen-share {
    max-width: 70%;
  }
}

@media only screen and (min-width: 1500px) {
  .modal-dialog {
    &.cating-answer-layout {
      max-width: 618px;
      margin: 0 auto;
    }

    &.audio-video-content {
      max-width: 60%;
      margin: 0 auto;
    }

    &.join-event-permission,
    &.confirmation-popup {
      max-width: 40%;
    }

    &.event-information-content {
      max-width: 55%;
      margin: 0 auto;
    }

    &.gift-design {
      max-width: 70%;
    }

    &.ask-question {
      max-width: 50%;
    }

    &.connect-user,
    &.connect-req {
      max-width: 27%;
    }

    &.audiance-screen-share {
      max-width: 60%;
    }

    &.connect-user-information {
      max-width: 28%;
    }

    &.chating-answer {
      max-width: 28%;
    }
  }
}

// Question part Css //
.question-part-caption {
  display: flex;
  font-family: "grotesk_medium";
  font-style: normal;
  font-weight: 500;
  color: #000000;
  align-items: center;
  margin-bottom: 12px;

  h5 {
    font-size: 12px;
    line-height: 17px;
    margin: 0px;
  }

  span {
    width: 4px;
    height: 4px;
    background: #1a09f4;
    margin-right: 4px;
    margin-left: 4px;
    border-radius: 100%;
  }
}

.ans-part-caption {
  background: #f8f9fc;
  border: 0.5px solid rgba(26, 9, 244, 0.05);
  box-sizing: border-box;
  border-radius: 2px;
  margin-top: 12px;
  padding: 12px 21px 20px 12px;

  p {
    font-family: "gibson_book";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    margin: 0px;
  }
}

.question-layout {
  padding: 0.8em;
}

// End //

@media only screen and (max-width: 1080px) {
  .modal-dialog.audio-video-content {
    height: auto;
    margin: 1.75rem auto;
  }

  // .modal-dialog.audio-video-content {
  //   max-width: 90%;
  //   margin: 1.75rem auto;
  // }
  .modal-dialog.event-information-content {
    max-width: 90%;
    margin: 1.75rem auto;
  }
}

@media only screen and (max-width: 767px) {
  .modal-dialog.audio-video-content {
    max-width: 60%;
    margin: 1.75rem auto;
  }
}

@media only screen and (max-width: 520px) {
  .modal-dialog.audio-video-content {
    max-width: 80%;
    margin: 1.75rem auto;
  }
}

// Harsh Scss //

// Colors Class //
.background-black {
  background-color: $black;
}
.side-nav-bar-content-container .mat-tab-labels {
  display: flex;
  justify-content: space-around;
}
.color-black {
  color: $black;
}

.color-white {
  color: $white;
}

.color-primary {
  color: $primary-color  !important;
}
.bg-white{
  background-color: $white;
}
// Height And Width Class //
.height-44 {
  height: 44px;
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.min-width-117 {
  min-width: 117px;
}

// End //

// Font-size //
.font-9 {
  font-size: 9px;
}

.font-10 {
  font-size: 10px;
}

.font-10 {
  font-size: 10px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-12 {
  font-size: 12px;
}

.font-22 {
  font-size: 22px;
}

.font-20 {
  font-size: 20px;
}

// End //

// Line Height //
.line-height-9 {
  line-height: 9px;
}

.line-height-22 {
  line-height: 22px;
}

.line-height-24 {
  line-height: 24px;
}

.line-height-32 {
  line-height: 32px;
}

.line-height-26 {
  line-height: 26px;
}

.line-height-20 {
  line-height: 20px;
}

.line-height-16 {
  line-height: 16px;
}

.line-height-17 {
  line-height: 17px;
}

.line-height-14 {
  line-height: 14px;
}

.line-height-12 {
  line-height: 12px;
}

.padding-left-right-40 {
  padding-left: 40px;
  padding-right: 40px;
}

// End //

// letter Spacing //
.letter-spacing-0-2 {
  letter-spacing: -0.02em;
}

// End //

.user-name-caption div {
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Button Common //
.btn-common {
  background: $primary-color;
  border: 2px solid $primary-color;
  box-sizing: border-box;
  border-radius: 1px;
  color: $white;
  padding: 4px 30px;
  height: 40px;
}

.btn-border-outline {
  border: 2px solid $primary-color;
  box-sizing: border-box;
  border-radius: 1px;
  background: $white;
  color: $primary-color;
  padding: 4px 30px;
  height: 40px;

  &:hover {
    background-color: $primary-color;
    color: $white;
  }
}

// End //

// Scroll bar in Chat List //

::-webkit-scrollbar {
  width: 4px; // for vertical scroll bar
}

/* Let's get this party started */
.scroller::-webkit-scrollbar {
  width: 4px;
  scrollbar-width: thin;
}
.mat-tab-body-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  transition: height 500ms cubic-bezier(0.35, 0, 0.25, 1);
  height: calc(100vh - 195px);
}
/* Track */
.scroller::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.streams {
  scrollbar-width: none;
}

@supports (scrollbar-width: thin) {
  .no-support {
    display: none;
  }
}

.scrollbar-width-thin {
  overflow: hidden;
}

/* Handle */
.scroller::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #e7e7e7;
}

.scroller::-webkit-scrollbar-thumb:window-inactive {
  background: #e7e7e7 !important;
}

// End //

.modal-body {
  padding: 1rem 1.9rem;
}

// Opacity //
.opacity-0-8 {
  opacity: 0.8;
}

// End //

// Footer layout //
.main-footer-layout {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: white;
  z-index: 2;
}

.video-footer-bar {
  position: fixed;
  width: 100%;
  bottom: 44px;
}

// End //

// Font-Family Class //

// End //

// Draggable Box //

// End //

// Matrial scss //
.nav-right-layout .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
  display: none !important;
}

.nav-right-layout {
  padding: 0 0px;
  min-width: 100px;
}

.nav-right-layout {
  height: 5px;
  background-color: $primary-color  !important;
}

// End //

// CheckBox Scss //
.form-group {
  display: block;
  margin-bottom: 0px;
}
.cursor-pointer{
  cursor: pointer !important;
}
.mat-tab-body-content{
  overflow: hidden !important;
}
.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
  font-family: $gibson-book-font;
  opacity: 0.8;
}

.form-group input:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  left: 6px;
  width: 4px;
  height: 8px;
  border: solid $primary-color;
  border-width: 0 1px 1px 0px;
  transform: rotate(45deg);
}

.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid $black;
  box-shadow: 0 1px 0px rgb(0 0 0 / 5%), inset 0px -15px 10px -18px rgb(0 0 0 / 5%);
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 8px;
  top: -2px;
}

.form-group input:checked+label:before {
  border-color: $primary-color;
}

// End //

.grey-shade1 {
  background-color: $grey-shade1;
}

.red-light {
  color: $red-light;
}

.btn-primary-color {
  border-color: $primary-color  !important;
}


// Slick Slider Arrow //
.connect-md-layout .slick-prev {
  left: 208px;
}

.connect-md-layout .slick-next {
  right: 168px;
}

.connect-md-layout .slick-prev,
.slick-next {
  height: auto;
  width: auto;
  top: 100%;
  transform: translate(0, 155%);
}

.connect-md-layout .slick-next,
.slick-prev {
  height: auto;
  width: auto;
  top: 100%;
  transform: translate(0, 155%);
}

// End //

/////chirag
.box-profile {
  width: 100%;
  position: relative;
  border: 2px solid black;
}

.OT_publisher,
.OT_subscriber {
  border-radius: 5px;
}

.custome-tabing-box .mat-tab-header.mat-tab-header {
  border: none;
  box-shadow: 0px 0px 20px #80808033;
  margin-bottom: 9px;
}

.grid {
  // display: flex;
  // flex-wrap: wrap;
  width: 100% !important;
  height: 100% !important;
  padding: 2px 0px 0px 2px;
  overflow: hidden;
  background-color: $black;
  position: relative;
  z-index: 1;
}

.grid-item {
  // width: calc((33.3333%) + 0px);
  // height: calc((33.3333%) + 0px);
  width: 100%;
  height: 100%;
  padding-right: 2px;
  padding-bottom: 2px;
}

.user-add-layout div {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.li-stream-blank {
  background: #252525;
  color: $white;
  height: 100%;
  border-radius: 4px;
}

.user-name-caption {
  width: 100%;
  height: 100%;
}

.user-name-caption img {
  object-fit: cover;
  width: 100%;
}
.mat-dialog-container{
  overflow: hidden !important;
}
.option-modalbox-layout mat-dialog-container {
  padding: 0px;
}
.coustom-user-video-none {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

// Video Profile //
.box-profile {

  // position: relative;
  // height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 0px;
  }
}

.customer-profile-img {
  height: 100%;
}
// .custom-user-caption {
// 	visibility: hidden;
// 	transition: 0.5s;
// }
.box-profile {
	&:hover {
		.custom-user-caption {
			visibility: visible;
			transition: 0.5s;
		}
	}
}
.custom-user-caption {
  z-index: 1;
  position: absolute;
  bottom: 0px;
  right: 0;
  width: 100%;
  display: none;
  transition: 0.5s;
  background: linear-gradient(92.19deg, #0002EB 0.34%, rgba(66, 67, 236, 0.62) 35.22%, rgba(113, 202, 160, 0.35) 52.69%, rgba(63, 63, 63, 0.382024) 65.59%, #040404 104.63%);
  opacity: 0.75;
}

.circle-dot {
  width: 4px;
  height: 4px;
  background: #0022EB;
  display: inline-block;
  border-radius: 100px;
  position: relative;
  right: -5px;
  top: -1px;
}

.top-box-layout {
  position: absolute;
  width: 100%;
  z-index: 0;
  padding: 20px 20px;
  top: 0;
}

.box-tooltip {
  width: 28px;
  height: 28px;
  background: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  z-index: 1;
}

// End //

// DropDown Css //
.user-layout-drp {
  .dropdown-item {
    width: 100%;
    padding: 9px 11px;
    clear: both;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-family: $gibson-book-font;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    color: #000000;
    display: flex;
    justify-content: space-between;

    &:last-child {
      color: $red;
    }
  }

  .dropdown-menu.show {
    display: block;
    padding: 0px;
    border-radius: 2px;
    background: $white;
    border: 1px solid rgba(26, 9, 244, 0.05);
    box-sizing: border-box;
    left: unset !important;
    right: 0;
  }
  // margin-left: 98%;

  margin-left: 20%;
  margin-top: 0.5cm;
}

.user-layout-drp.dropdown {
  position: absolute;
  right: 20px;
  top: 20px;
}

.box-position-right {
  position: absolute;
  right: 30px;
}

.box-position-left {
  position: absolute;
  left: 25px;
}

.dropdown-toggle {
  &::after {
    display: none;
  }
}

.user-btn-drp {
  &:focus {
    box-shadow: none !important;
  }

  margin: 0px;
  padding: 0px;
  border: none;
}

.box-tooltip img {
  max-width: 15px !important;
  object-fit: contain !important;
  margin-left: 6px;
}

// end //

// Responsive Media Query //

@media (max-width: 1399.98px) {
  .user-name-caption {
    width: 75px;
    height: 75px;
  }

  .user-sub-info {
    h4 {
      font-size: 16px;
      line-height: 20px;
    }

    p {
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 5px;
    }

    h3 {
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.grid-item.float-user1 {
  position: absolute;
  z-index: 100;
  min-width: 200px;
  min-height: 150px;
  overflow: hidden;
  // border-radius: 12px;
  top: 10px;
  right: 30px;
  width: 20% !important;
  height: 20% !important;
  border: 0;
  border: 1px solid #e8e8e8;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.grid-item.float-user1 * {
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

.grid-item {
  display: inline-block;
  //  overflow: hidden;
  /* border-radius: 12px; */
  z-index: 1;
  background-color: transparent;
  border: 0px solid #e8e8e8;
  box-sizing: border-box;
  position: relative;
  font-size: 12px;
  line-height: 14px;
  padding: 4px;
  background: #f7f7f7;
  /* margin-left: 4px; */
  /* margin-bottom: 4px;*/
}

.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropdown-content p {
  font-size: 14px;
  margin: 6px 0px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
  padding: 8px 15px;
  z-index: 1;
  right: 0;
  border-radius: 2px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

//Chirag

// Video Stream Grid Layout Css //
.user_1 .box-profile:nth-child(1) {
  width: 100%;
}

.user_2 .box-profile:nth-child(1) {
  width: 50%;
}

.user_2 .box-profile:nth-child(2) {
  width: 50%;
}


.user_3 .box-profile:nth-child(1) {
  width: 33.3%;
}

.user_3 .box-profile:nth-child(2) {
  width: 33.3%;
}

.user_3 .box-profile:nth-child(3) {
  width: 33.3%;
}


.user_4 .box-profile:nth-child(1) {
  width: 50%;
}

.user_4 .box-profile:nth-child(2) {
  width: 50%;
}

.user_4 .box-profile:nth-child(3) {
  width: 50%;
}

.user_4 .box-profile:nth-child(4) {
  width: 50%;
}


.user_5 .box-profile:nth-child(1) {
  width: 33.3%;
}

.user_5 .box-profile:nth-child(2) {
  width: 33.3%;
}

.user_5 .box-profile:nth-child(3) {
  width: 33.3%;
}

.user_5 .box-profile:nth-child(4) {
  width: 50%;
}

.user_5 .box-profile:nth-child(5) {
  width: 50%;
}


.user_6 .box-profile:nth-child(1) {
  width: 33.3%;
}

.user_6 .box-profile:nth-child(2) {
  width: 33.3%;
}

.user_6 .box-profile:nth-child(3) {
  width: 33.3%;
}

.user_6 .box-profile:nth-child(4) {
  width: 33.3%;
}

.user_6 .box-profile:nth-child(5) {
  width: 33.3%;
}

.user_6 .box-profile:nth-child(6) {
  width: 33.3%;
}


.user_7 .box-profile:nth-child(1) {
  width: 33.3%;
}

.user_7 .box-profile:nth-child(2) {
  width: 33.3%;
}

.user_7 .box-profile:nth-child(3) {
  width: 33.3%;
}

.user_7 .box-profile:nth-child(4) {
  width: 25%;
}

.user_7 .box-profile:nth-child(5) {
  width: 25%;
}

.user_7 .box-profile:nth-child(6) {
  width: 25%;
}

.user_7 .box-profile:nth-child(7) {
  width: 25%;
}


.user_8 .box-profile:nth-child(1) {
  width: 25%;
}

.user_8 .box-profile:nth-child(2) {
  width: 25%;
}

.user_8 .box-profile:nth-child(3) {
  width: 25%;
}

.user_8 .box-profile:nth-child(4) {
  width: 25%;
}

.user_8 .box-profile:nth-child(5) {
  width: 25%;
}

.user_8 .box-profile:nth-child(6) {
  width: 25%;
}

.user_8 .box-profile:nth-child(7) {
  width: 25%;
}

.user_8 .box-profile:nth-child(8) {
  width: 25%;
}


.user_9 .box-profile:nth-child(1) {
  width: 33.3%;
}

.user_9 .box-profile:nth-child(2) {
  width: 33.3%;
}

.user_9 .box-profile:nth-child(3) {
  width: 33.3%;
}

.user_9 .box-profile:nth-child(4) {
  width: 33.3%;
}

.user_9 .box-profile:nth-child(5) {
  width: 33.3%;
}

.user_9 .box-profile:nth-child(6) {
  width: 33.3%;
}

.user_9 .box-profile:nth-child(7) {
  width: 33.3%;
}

.user_9 .box-profile:nth-child(8) {
  width: 33.3%;
}

.user_9 .box-profile:nth-child(9) {
  width: 33.3%;
}

.screen-pos {
  position: absolute;
    height: 20%;
    overflow: scroll;
    width: 100%;
    z-index: 99999;
}
// End //


.md-label{
  z-index: 222222222;
  position: absolute;
  right: 0;
  max-width: 70px;
  max-height: 28px;
  padding: 0px;
  top: 3%;
}
.speaker-logo-img {
  width: 50px;
  height: 50px;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: none !important;
  border-left: none !important;
  border-bottom: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
  transform: rotate(45deg);
  height: 6px;
  width: 6px;
  position: absolute;
  cursor: pointer;
  right: 7%;
  top: 40%;
}
.emoji-mart-scroll{
  overflow-y: scroll;
  height: 100% !important;
  padding: 0 6px 6px 6px;
  will-change: transform;
}
.carousel-control-next {
  right: 36% !important;
  width: 35px !important;
}
.carousel-control-prev {
  left: 47% !important;
  width: 35px !important;
}
.carousel-control-next, .carousel-control-prev{
  top: 100% !important;
  opacity: 0 !important;
}
.carousel-indicators{
  background-color: red !important;
}

///siddharth

#appDragg-layout {
  position: absolute;
  z-index: 9;
  text-align: center;
  width: 209px;
  height: 165px;
  padding: 3px;
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-clip-path: polygon(100% 0, 100% 85%, 75% 85%, 75% 100%, 50% 86%, 0 86%, 0 0);
  clip-path: polygon(100% 0, 100% 85%, 75% 85%, 75% 100%, 50% 86%, 0 86%, 0 0);
  right: 8%;
  bottom: 19%;
  display: none;
  cursor: move;
}
.waiting-userImg .avatar-custom {
  flex-direction: column;
}
#drag-active-box {
  position: absolute;
  z-index: 9;
  text-align: center;
  width: 209px;
  height: 165px;
  padding: 3px;
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-clip-path: polygon(100% 0, 100% 85%, 75% 85%, 75% 100%, 50% 86%, 0 86%, 0 0);
  clip-path: polygon(100% 0, 100% 85%, 75% 85%, 75% 100%, 50% 86%, 0 86%, 0 0);
  right: 8%;
  bottom: 19%;
  cursor: move;
  display: block;
}
#appDragg-Box {
  padding: 0;
  cursor: move;
  z-index: 10;
  color: #fff;
  height: 100%;
}
#pgrid {
    height: 100%;
    background-color: black;
}
.screen-share {
  position: absolute;
  width: 100%;
  height: calc(100% - 287px);
}
div#screen-preview {
  width: 100% !important;
  height: 100% !important;
}
.screenlayout {
  max-height: 155px !important;
  padding: 0px 125px !important;
}
.OT_publisher, .OT_subscriber {
  min-width: 100% !important;
  min-height: 100% !important;
}
.screenlayout .box-profile {
  width: 180px !important;
  height: 150px !important;
}
.chat-dot {
  width: 4px;
  height: 4px;
  background: #0022EB;
  border-radius: 10px;
  margin-right: 5px;
}


::-webkit-scrollbar {
    width: 0;
  }
.videooff-details{
  margin-top: 92px;
  margin-left: 290px;
}

.speaker-profile-img {
  background: white;
  height: 100px;
  width: 100px;
  margin: auto;
  margin-bottom: 10px;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
}
.speaker-profile-img img {
  object-fit: contain;
}

// Emoji preview //
.emoji-mart-preview{
  display: none;
}
.emoji-mart {
  width: 100%;
  height: 100%;
  overflow: auto;
}
// End //

// .image-profile {
//   position: absolute;
//   bottom: 0;
//   top: 0;
//   width: 100%;
//   z-index: 99;
//   display: flex !important;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//   background-color: #252525;
// }

.loaderSpinner {
  width: 25px;
  height: 25px;
  display: inline-block;
  padding: 0px;
  border-radius: 100%;
  border: 5px solid;
  border-top-color: $white;
  border-bottom-color: rgba(255, 255, 255, 0.3);
  border-left-color: $white;
  border-right-color: rgba(255, 255, 255, 0.3);
  -webkit-animation: loaderSpinner 1s ease-in-out infinite;
  animation: loaderSpinner 1s ease-in-out infinite;
}
@keyframes loaderSpinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loaderSpinner {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.loader-wrapper2 {
  padding: 0px;
  // height: 25px;
  width: auto;
  margin-left: 10px;
}
.swal2-html-container{
  font-family: 'gibson_book';
}
.pop-up-overflow{
  overflow: scroll;
  height: 230px;
}

@media (max-width: 1280px) {
  .speaker-profile-img {
    background: white;
    height: 70px;
    width: 70px;
    margin: auto;
    margin-bottom: 10px;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
  }
}

// End //

