@font-face {
  font-family: "gibson_book";
  src: url("../assets/fonts/Gibson_Book_web/Gibson-Book.woff2") format("woff2"),
    url("../assets/fonts/Gibson_Book_web/Gibson-Book.woff") format("woff");
}
@font-face {
  font-family: "gibson_medium";
  src: url("../assets/fonts/Gibson_Medium_web/Gibson-Medium.woff2") format("woff2"),
    url("../assets/fonts/Gibson_Medium_web/Gibson-Medium.woff") format("woff");
}

@font-face {
  font-family: "grotesk_regular";
  src: url("../assets/fonts/Roc_Grotesk_Regular/e8cf596e-4fb1-4829-a7e7-84597a647cd1.woff2") format("woff2"),
    url("../assets/fonts/Roc_Grotesk_Regular/3d9eacb0-940f-4d17-8494-62f93a208c8b.woff") format("woff");
}
@font-face {
  font-family: "grotesk_medium";
  src: url("../assets/fonts/Roc_Grotesk_Medium/f3d78119-f633-4489-a2e3-8eceaabaa1be.woff2") format("woff2"),
    url("../assets/fonts/Roc_Grotesk_Medium/3a9252cd-8be2-4789-8860-6d54dd13b0b6.woff") format("woff");
}

@font-face {
  font-family: "Roc Grotesk";
  src: url("../assets/fonts/Roc_Grotesk_Medium/3a9252cd-8be2-4789-8860-6d54dd13b0b6.woff") format("woff"),
    url("../assets/fonts/Roc_Grotesk_Medium/f3d78119-f633-4489-a2e3-8eceaabaa1be.woff2") format("woff2");
}

// Font-family //

$gibson-medium-font: "gibson_medium";
$gibson-book-font: "gibson_book";
$grotesk-regular: "grotesk_regular";
$grotesk-medium: "grotesk_medium";
$secondary-font: "Roc Grotesk";

// end //

// New Font Family Class //

.gibson_medium {
  font-family: "gibson_medium" !important;
}
.gibson-book-font {
  font-family: "gibson_book" !important;
}
.grotesk-regular {
  font-family: "grotesk_regular";
}
.grotesk-medium {
  font-family: "grotesk_medium" !important;
}
.secondary-font {
  font-family: "Roc Grotesk" !important;
}
.SpeakerFont{
  text-align: initial;
}
